/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Button, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Cadeau de mariage / Regalo di nozze"}>
        <SiteHeader />

        <Column className="css-12ekc0m css-1a0oube --parallax pb--50 pt--80" name={"contact"} border={null} layout={"l13"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_s=3000x_.jpg);
    }
  
background-position: 50% 5%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"gift-registry-1"} style={{"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 mt--50 flex--top" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--center fs--26" content={"Paris - Constantinople <br>❤ ORIENT EXPRESS ❤"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn1 btn-box--pbtn4 btn-box--shape4 btn-box--cColor2 btn-box--design2 btn-box--filling2 btn-box--center fs--26 btn-box--invert mt--20" url={"https://www.onparticipe.fr/c/d0bYxkBw/new"} href={"https://www.onparticipe.fr/c/d0bYxkBw/new"} style={{"backgroundColor":"rgba(9,80,3,0.39)"}} target={"_blank"} content={"<span style=\"color: rgb(255, 254, 254);\">Participate</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"e0e5430k0ym"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"VOYAGE DE NOCES&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Vous avez été nombreux à nous demander notre liste de mariage &lt;3\nComme vous le savez, nous n'en avons pas... ! Cependant, nous avons un rêve...qui, peut être grâce à vous, deviendra une réalité : un voyage en Orient Express.\nNous avons donc mis en ligne un cagnotte afin que chacun puisse contribuer à hauteur de ses moyens et de ses envies.\nUn grand merci par avance pour votre participation."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--30" content={"<span style=\"color: rgba(247, 113, 166, 0.75);\">LUNA DI MIELE</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: rgba(247, 113, 166, 0.78);\">In tanti ci avete chiesto la nostra lista nozze &lt;3 <br>Come sapete, non ne abbiamo una...! Abbiamo però un sogno... che, forse grazie a voi, diventerà realtà: un viaggio sull'Orient Express. Abbiamo quindi messo online una cassa commune in modo che ognuno possa contribuire secondo i propri mezzi e desideri. Molte grazie in anticipo per la vostra partecipazione.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-ehoebf --center --parallax pb--80 pt--60" name={"footer"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31913/64942147052341efa98ae6ec38f3be6f_bri=85__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box" content={"<span style=\"color: rgb(249, 249, 249);\">Nous avons hâte de vous voir!</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"<span style=\"color: rgb(246, 247, 249);\">Non vediamo l'ora di vedervi!</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}